<template>
  <div class="content">
    <div class="tltle-header">
      <div class="header-l">
        <i></i>
        <span>行业资讯</span>
      </div>
    </div>
    <div class="news-list">
      <div
        class="news-lis"
        v-for="(data, ind) in getNewsListData"
        :key="ind"
        @click="Newxw(data)"
      >
        <i></i>
        <p>
          {{ data.newsTitle }}
        </p>
        <span>{{ TimestampToDate(data.newsTime) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "infoList",
  components: {},

  data() {
    return {
      getNewsListData: [],
      // userId:'',
      // cityCode:'-1',
      // token:'',
      // info : JSON.parse(this.$userinfo),
      // sessionId:this.$sessionId
    };
  },
  created() {
    // this.sessionId=localStorage.getItem('sessionId')
    // let userinfo=localStorage.getItem('userinfo');
    // this.userId=JSON.parse(userinfo).userId
    this.getNewsList();
  },
  mounted() {},
  methods: {
    TimestampToDate(Timestamp) {
      let date1 = new Date(Timestamp * 1000);
      return date1.toLocaleDateString().replace(/\//g, "-");
    },
    Newxw(data) {
      // this.$router.push({name:'Newdetails', params: {newsId: data.newsId}})
      this.$router.push({
        path: "/Newdetails",
        query: { newsId: data.newsId },
      });
    },
    getNewsList() {
      // if(this.sessionId === '' || this.sessionId == null){
      //   this.sessionId = ''
      // }else{
      //   this.sessionId=this.sessionId
      // }
      // if(this.info.userId === '' || this.info.userId == null){
      //   this.userId = '2'
      // }else{
      //   this.userId=this.userId
      // }

      let param = {
        pageSize: 4,
        currPage: 1,
      };
      this.$axios
        .post("/api/NewsListInterface/getNewsList", param)
        .then((res) => {
          this.getNewsListData = res.data.data.list;
          console.log(this.getNewsListData);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.tltle-header {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  .header-l {
    display: flex;
    align-items: center;
    i {
      width: 5px;
      height: 16px;
      background: #ff5e5e;
      border-radius: 5px 5px 5px 5px;
    }
    span {
      margin-left: 6px;
      font-size: 20px;
      font-weight: 600;
      color: #010101;
    }
  }
}
.news-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .news-lis {
    width: 48%;
    display: flex;
    margin-right: 20px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    i {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ff5e5e;
    }
    :nth-child(1) {
      margin-left: 0px;
    }
    :nth-child(3) {
      margin-left: 0px;
    }
    span {
      color: #a2a7b2;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: #222222;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
    }
    p:hover {
      // border-bottom: 1px solid #222222;
      color: #2e5caa;
    }
  }
}
</style>