<template>
  <el-carousel height="380px">
    <el-carousel-item v-for="(item,index) in banner" :key="index" v-if="banner">
      <el-image
          style="width: 100%; height: 100%"
          :src="item"
          fit="cover"></el-image>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "carousel",
  created() {
    this.getData()
  },
  mounted() {

  },
  data() {
    return {
      bannerUrl:'api/SystemInterface/getComSlideList',
      host:'https://www.esgjsb.com',
      banner:[]
    }
  },
  methods: {
     getData(){
      let that = this
      let i = 0
       this.$axios.post(this.bannerUrl, {
        userId:'2',
        cityCode:'-1'
      }).then(function (response) {
            if(response.data.code === "0"){
              let bannerData = response.data.data
              bannerData.forEach(function (value,index,array){
                // that.banner[i] = that.host+value.bannerImageUrl
                that.banner.push(that.host+value.bannerImageUrl)
              })
            }
          }).catch(function (error) {
            console.log(error);
          });

    }
  },
}
</script>

<style lang="less" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #2a7ca8;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #1c9d5d;
}
/deep/ .el-carousel__indicators--horizontal{
  // bottom: 99px !important;
}

</style>