<template>
  <!-- <div>
    <h2>{{title}}111</h2>
    <div :class="isHeight ?'brand-box':'brand-box-height'">
      <el-row type="flex" justify="" >
        <el-col :span="8" v-for="(item,index) in listData" :key="index" :offset="index > 0 ? 2 : 0" class="cardStyle">
          <el-card :body-style="{ padding: '0px' }" @click.native="toList(item)">
            <div class="image-box">
              <el-image :src="host+item.chexingImage" class="image" fit="contain"></el-image>
            </div>
            <div style="padding: 14px;">
              <h3>{{ item.chexingTitle }}</h3>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-button type="info" round @click="changeHeight">展开更多</el-button>
  </div> -->

  <div>
    <div class="tltle-header">
      <div class="header-l">
        <i></i>
        <span>推荐店铺</span>
      </div>
      <span class="view" @click="ckgdClick">查看更多</span>
    </div>
    <div class="dp-list">
      <div class="dp-lis" v-for="(data, ind) in getShopListData" :key="ind">
        <div class="lis-l">
          <div class="img">
            <img :src="host + data.storeLogo" alt="" />
          </div>

          <div class="title">
            <b>{{ data.shoreName }}</b>
            <span>在售设备：{{ data.storeCarNum }}</span>
          </div>
        </div>
        <div class="lis-r" @click="toShop(data)">进店查看</div>
      </div>
    </div>
    <!-- <div :class="isHeight ? 'brand-box' : 'brand-box-height'">
      <el-row type="flex" justify="">
        <el-col
          :span="8"
          v-for="(item, index) in listData"
          :key="index"
          :offset="index > 0 ? 2 : 0"
          class="cardStyle"
        >
          <el-card
            :body-style="{ padding: '0px' }"
            @click.native="toList(item)"
          >
            <div class="image-box">
              <el-image
                :src="host + item.pinpaiImage"
                class="image"
                fit="contain"
              ></el-image>
            </div>
            <div style="padding: 14px">
              <h3>{{ item.pinpaiName }}</h3>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div> -->
    <!-- <el-button type="info" round @click="changeHeight">展开更多</el-button> -->
  </div>
</template>

<script>
export default {
  name: "device",
  props: {
    title: {
      type: String,
      default: "",
    },
    listData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isHeight: true,
      getShopListData: [],
      host: "https://www.esgjsb.com",
    };
  },
  created() {
    this.getShopList();
  },
  methods: {
    ckgdClick(){
      this.$router.push({name:'myshop'})
    },
    changeHeight() {
      this.isHeight = !this.isHeight;
    },
    toList(e) {
      console.log("设备", e);
    },
    toShop(data){
      this.$router.push({name:'shopDetail', params: {storeId: data.storeId}})
      localStorage.setItem('storeId',data.storeId);
    },
    getShopList() {
      this.$axios
        .post("/api/ShopListInterface/getShopList", {
          pageSize: 4,
          currPage: 1,
        })
        .then((res) => {
          console.log(res.data.data.list);
          this.getShopListData = res.data.data.list;
        });
    },
    // /api/ShopListInterface/getShopList
  },
};
</script>

<style lang="less" scoped>
// .image-box{
//   width: 100%;
//   height: 160px;
//   padding: 30px;
//   box-sizing: border-box;
// }
// .image {
//   width: 100%;
//   height: 100%;
//   display: block;
//   margin: auto;
// }
// .cardStyle{
//   width: 210px;
//   height: 250px;
//   border-radius: 10px;
//   margin: 35px;
//   cursor: pointer;
// }
// .el-card{
//   background-color: #EBF2FF;
//   width: 210px;
//   height: 270px;
// }
// .el-row--flex {
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: flex-start;
// }
// .brand-box{
//   height: 330px;
//   overflow: hidden;
// }
// .brand-box-show{
//   height: auto;
// }

.tltle-header {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  .header-l {
    display: flex;
    align-items: center;
    i {
      width: 5px;
      height: 16px;
      background: #ff5e5e;
      border-radius: 5px 5px 5px 5px;
    }
    span {
      margin-left: 6px;
      font-size: 20px;
      font-weight: 600;
      color: #010101;
    }
  }
  .view {
    font-size: 14px;
    color: #696969;
    border-bottom: 1px solid #696969;
    cursor: pointer;
  }
}
.dp-list {
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  .dp-lis {
    width: 46%;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    align-items: center;
    margin-top: 30px;
    .lis-l {
      display: flex;
      flex: 1;
      .img {
        width: 160px;
        height: 128px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .title {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        justify-content: space-between;
        padding: 10px 0;
        flex: 1;
        b {
          font-size: 18px;
          flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
        }
        span {
          font-size: 14px;
          color: #525252;
        }
      }
    }
    .lis-r {
      font-size: 16px;
      color: #2e5caa;
      font-weight: 600;
      margin-right: 40px;
      cursor:pointer;
    }
  }
}
</style>