import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  // {
  //   path:'/',
  //   redirect:'/home',
  // },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta:{
      // 头部标识显示
      title: '首页',
      noNeedLogin: true, // 不需要登录的页面
      content:{
        keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
        description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
      }
    }
  },
  {
    path: '/login',
    name:'login',
    component: () => import('@/views/Login'),
    meta:{
      // 头部标识显示
      title: '登录',
      noNeedLogin: true, // 不需要登录的页面
    }
  },

  {
    path: '/myshop',
    name: 'myshop',
    redirect: '/myshop/shopList',
    component: () => import('@/views/myShop'),
    meta:{
      // 头部标识显示
      title: '店铺',
      content:{
        keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
        description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
      },
      noNeedLogin: true, // 不需要登录的页面
    },
    children:[{
      path: '/myshop/shopList',
      name: 'shopList',
      component: () => import('@/views/shopComponents/shopList'),
      meta:{
        // 头部标识显示
        title: '店铺',
        content:{
          keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
          description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
        },
        noNeedLogin: true, // 不需要登录的页面
      },
    },{
      path: '/myshop/shopDetail',
      name: 'shopDetail',
      component: () => import('@/views/shopComponents/shopDetail'),
      meta:{
        // 头部标识显示
        title: '店铺',
        content:{
          keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
          description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
        },
        noNeedLogin: true, // 不需要登录的页面
      },
    },]

  },
  {
    path: '/publish',
    name: 'publish',
    component: () => import('@/views/publish'),
    meta:{
      // 头部标识显示
      title: '发布',
      noNeedLogin: false, // 不需要登录的页面
      content:{
        keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
        description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
      }
    }
  },
  {
    path: '/realNameAuthentication',
    name: 'realNameAuthentication',
    component: () => import('@/views/realNameAuthentication'),
    meta:{
      // 头部标识显示
      title: '实名认证',
      noNeedLogin: false, // 不需要登录的页面
    }
  },
  {
    path: '/informationList',
    name: 'informationList',
    component: () => import('@/views/informationList'),
    meta:{
      // 头部标识显示
      title: '行业资讯',
      content:{
        keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
        description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
      },
      noNeedLogin: true, // 不需要登录的页面
    }
  },
  {
    path: '/afterSales',
    name: 'afterSales',
    component: () => import('@/views/afterSales'),
    meta:{
      // 头部标识显示
      title: '售后服务',
      content:{
        keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
        description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
      },
      noNeedLogin: true, // 不需要登录的页面
    }
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: () => import('@/views/personalCenter'),
    meta:{
      // 头部标识显示
      title: '个人中心',
      content:{
        keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
        description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
      },
      noNeedLogin: false, // 不需要登录的页面
    }
  },
  {
    path: '/deviceDetail',
    name: 'deviceDetail',
    component: () => import('@/views/deviceDetails'),
    meta:{
      // 头部标识显示
      title: '设备详情',
      noNeedLogin: true, // 不需要登录的页面
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search'),
    meta:{
      // 头部标识显示
      title: '搜索',
      noNeedLogin: true, // 不需要登录的页面
    }
  },

  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('@/views/productDetails'),
    meta:{
      title: '商品详情',
      content:{
        keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
        description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
      },
      noNeedLogin: true, // 不需要登录的页面
    }
  },
  {
    path: '/Newdetails',
    name: 'Newdetails',
    component: () => import('@/views/Newdetails'),
    meta:{
      title: '商品详情',
      noNeedLogin: true, // 不需要登录的页面
    }
  },
  {
    path: '/Mbindex',
    name: 'Mbindex',
    component: () => import('@/views/Mbindex'),
    meta:{
      // 头部标识显示
      title: '',
      noNeedLogin: true, // 不需要登录的页面
    }
  },
  {
    path: '/BrandClassification',
    name: 'BrandClassification',
    component: () => import('@/views/homeComponents/BrandClassification'),
    meta:{
      title: '分类',
      content:{
        keywords:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线',
        description:'锯切套丝生产线,钢筋锯切线,钢筋剪切线,钢筋笼滚焊机,钢筋弯曲中心,钢筋笼绕笼机,钢筋锯切生产线'
      },
      noNeedLogin: true, 
    }
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// 路由拦截
router.beforeEach((to, from, next) => {
  if (!to.meta.noNeedLogin) {
    if (localStorage.getItem('isLogin')==='1') {
      next();
    } else {
      next({ path: '/login' });
    }
  } else {
    next();
  }
});


router.afterEach((to, from, next) => {
  document.querySelector("body").setAttribute("style", "overflow: auto !important;")
  window.scrollTo(0, 0)
});




export default router
