<template>
  <div class="classification">
    <div class="classification-l">
      <b class="title">设备类型及品牌</b>
      <div class="classification-list">
        <div 
          class="classification-lis"
          v-for="(data, ind) in chexingList"
          :key="ind"
          @click="equipmentBrand(data,ind,'fl')"
        >
          <img :src="host + data.chexingImage" alt="" />
          <span>{{ data.chexingTitle }}</span>
        </div>
        <div
          class="classification-lis"
          v-for="(list, ind1) in pinpaiList"
          :key="ind1"
          @click="equipmentBrand(list,ind1,'pp')"
        >
          <img :src="host + list.pinpaiImage" alt="" />
          <span>{{ list.pinpaiName }}</span>
        </div>
      </div>
    </div>
    <div class="classification-r">
      <b class="title">我要发布</b>
      <div class="fb">
        <img src="../../assets/images/home/fb.png" alt="" />
        <div class="btn">
          <span @click="release">快速发布</span>
          <span @click="Publish">免费评估</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import equipmentEvaluationPublish from "@/views/personal/equipmentEvaluationPublish";
export default {
  name: "brand",
  props: {
    title: {
      type: String,
      default: "",
    },
    // listData: {
    //   type: Array,
    //   default: [],
    // },
  },
  components: {
    equipmentEvaluationPublish,
  },
  data() {
    return {
      isHeight: true,
      dialogVisible: false,
      host: this.$host,
      // info: JSON.parse(this.$userinfo),
      // sessionId: this.$sessionId,
      // token: "",
      // cityCode: "-1",
      chexingList: [],
      pinpaiList: [],
    };
  },
  created() {
    this.getDictList();
  },
  methods: {
    equipmentBrand(data,ind,state){
      this.$router.push({name:'BrandClassification',params:{data:data,ind:ind,state:state}})
    },
    changeHeight() {
      this.isHeight = !this.isHeight;
    },
    toList(e) {
      console.log("品牌", e);
    },
    release() {
      this.$router.push({ name: "publish" });
    },
    Publish() {
      this.$router.push({ name: "personalCenter",params:{homestate:'1'} });

    },
    getDictList() {
      // const now = new Date();
      // const year = now.getFullYear();
      // const month = String(now.getMonth() + 1).padStart(2, "0");
      // const day = String(now.getDate()).padStart(2, "0");
      // const time = year.toString() + month + day;

      // this.token = this.$md5(
      //   this.sessionId +
      //     this.info.userId +
      //     this.cityCode +
      //     "jiudianlianxian" +
      //     time
      // );
      // let param = {
      //   sessionId: this.sessionId,
      //   userId: this.info.userId,
      //   cityCode: this.cityCode,
      //   token: this.token,
      // };
      this.$axios
        .post("/api/SystemInterface/getDictList")
        .then((res) => {
          let data = res.data.data;
          this.chexingList = data.chexingList.slice(0, 6);
          this.pinpaiList = data.pinpaiList.slice(0, 6);
        });
    },
  },
};
</script>

<style lang="less" scoped>

.image-box {
  width: 100%;
  height: 160px;
  padding: 30px;
  box-sizing: border-box;
}
.title {
  font-size: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #010101;
}
.classification {
  height: 256px;
  background: #ffffff;
  box-shadow: 0px 0px 21px 0px rgb(95, 91, 91);
  border-radius: 15px 15px 15px 15px;
  opacity: 1;
  position: absolute;
  left: 0;
  top: -81px;
  z-index: 99;
  width: 100%;
  display: flex;
  text-align: left;
  padding: 20px 16px;
}
.classification-l {
  flex: 8;
}
.classification-r {
  flex: 2;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // width: 100%;
  .fb {
    display: flex;
    flex-direction: column;

    align-items: center;
    img {
      width: 168px;
      height: 128px;
      margin-top: 20px;
    }
    .btn {
      display: flex;
      margin-top: 37px;
      span {
        width: 120px;
        height: 40px;
        border-radius: 12px 12px 12px 12px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
      }
      :nth-child(1) {
        background: #ff6c6c;
      }
      :nth-child(2) {
        background: #ffb86c;
        margin-left: 20px;
      }
    }
  }
}
.image {
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
}
.cardStyle {
  width: 210px;
  height: 250px;
  border-radius: 10px;
  margin: 35px;
  cursor: pointer;
}
.classification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .classification-lis {
    width: 15%;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    :nth-child(1) {
      margin-left: 00px;
    }
    :nth-child(7) {
      margin-left: 00px;
    }
    img {
      width: 77px;
      height: 61px;
    }
    span {
      margin-top: 10px;
    }
  }
}
.el-card {
  background-color: #ebf2ff;
  width: 210px;
  height: 270px;
}
.el-row--flex {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.brand-box {
  height: 330px;
  overflow: hidden;
}
.brand-box-show {
  height: auto;
}
</style>