<template>
  <div>
    <div class="tltle-header">
      <div class="header-l">
        <i></i>
        <span>推荐</span>
      </div>
      <span class="view" @click="more()">查看更多</span>
    </div>
    <div class="cp-list">
      <div class="cp-lis" v-for="(data, ind) in getCarListData" :key="ind" @click="ProductClick(data)">
        <img
          :src="host + data.carImg[0]"
          alt=""
          style="width: 262px; height: 173px"
        />
        <div class="num">
          <div class="address">
            <img
              src="../../assets/images/home/address.png"
              alt=""
              class="d-img"
            />
            <span>{{ data.shiname }}</span>
          </div>
          <div class="address address-l">
            <img src="../../assets/images/home/vive.png" alt="" class="y-img" />
            <span>{{ data.liulancishu }}次</span>
          </div>
        </div>
        <div class="h1">
          {{ data.title }}
        </div>
        <div class="money">￥{{ data.jiage }}{{danwei}}</div>
      </div>
    </div>
    <!-- <div :class="isHeight ? 'brand-box' : 'brand-box-height'">
      <el-row type="flex" justify="">
        <el-col
          :span="8"
          v-for="(item, index) in listData"
          :key="index"
          :offset="index > 0 ? 2 : 0"
          class="cardStyle"
        >
          <el-card
            :body-style="{ padding: '0px' }"
            @click.native="toList(item)"
          >
            <div class="image-box">
              <el-image
                :src="host + item.pinpaiImage"
                class="image"
                fit="contain"
              ></el-image>
            </div>
            <div style="padding: 14px">
              <h3>{{ item.pinpaiName }}</h3>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div> -->
    <!-- <el-button type="info" round @click="changeHeight">展开更多</el-button> -->
  </div>
</template>

<script>
export default {
  name: "brand",
  props: {
    title: {
      type: String,
      default: "",
    },
    listData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isHeight: true,
      getCarListData: [],
      danwei:'',
      host: "https://www.esgjsb.com",
    };
  },
  created() {
    this.getCarList();
    this.getDictList();
  },
  methods: {
    more(){
      this.$router.push({name:'BrandClassification'})
    },
    changeHeight() {
      this.isHeight = !this.isHeight;
    },
    toList(e) {
      console.log("品牌", e);
      // this.$router.push({name: 'search',params: {pinpaiId: e.pinpaiId}});
    },
    getCarList() {
      this.$axios
        .post("/api/CarListInterface/getCarList", { pageSize: 8, currPage: 1 })
        .then((res) => {
          this.getCarListData = res.data.data.list;
        });
    },
    ProductClick(data){
      this.$router.push({name:'productDetails',params:{data:data}})
      localStorage.setItem('carId',data.carId);
    },
    getDictList() {
      this.$axios
        .post("/api/SystemInterface/getDictList")
        .then((res) => {
          this.danwei=res.data.data.danwei
          
        });
    },
  },
};
</script>

<style lang="less" scoped>
.image-box {
  width: 100%;
  height: 160px;
  padding: 30px;
  box-sizing: border-box;
}
.image {
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
}
.tltle-header {
  display: flex;
  justify-content: space-between;
  padding-top: 265px;
  .header-l {
    display: flex;
    align-items: center;
    i {
      width: 5px;
      height: 16px;
      background: #ff5e5e;
      border-radius: 5px 5px 5px 5px;
    }
    span {
      margin-left: 6px;
      font-size: 20px;
      font-weight: 600;
      color: #010101;
    }
  }
  .view {
    font-size: 14px;
    color: #696969;
    border-bottom: 1px solid #696969;
    cursor: pointer;
  }
}
.cardStyle {
  width: 210px;
  height: 250px;
  border-radius: 10px;
  margin: 35px;
  cursor: pointer;
}
.el-card {
  background-color: #ebf2ff;
  width: 210px;
  height: 270px;
}
.el-row--flex {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.brand-box {
  height: 330px;
  overflow: hidden;
  // padding-top: 215px;
}

.brand-box-show {
  height: auto;
}

.cp-list {
  display: flex;
  flex-wrap: wrap;
  .cp-lis {
    width: 23%;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
    .num {
      display: flex;
      align-items: center;
      margin-top: 13px;
      .address {
        display: flex;
        align-items: center;

        .y-img {
          width: 20px;
          height: 12px;
        }
        .d-img {
          width: 16px;
          height: 20px;
        }
        span {
          font-size: 14px;
          color: #7f7f7f;
          margin-left: 6px;
        }
      }
      .address-l {
        margin-left: 20px;
      }
    }
    .h1 {
      font-size: 16px;
      font-weight: 600;
      color: #252525;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 16px;
    }
    .money {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #ff4747;
      text-align: left;
      margin-top: 16px;
    }
  }
}
</style>