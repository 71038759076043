<template>
  <div class="contenter">
    <el-container>
      <!-- <el-header class="header"><Head></Head></el-header> -->
      <el-main class="mainContent">
        <mainContent></mainContent>
      </el-main>
    </el-container>
    <el-footer class="footer" height="10rem"
      ><footerContent></footerContent
    ></el-footer>
  </div>
</template>
<script>
import Head from "@/components/Header";
import mainContent from "@/views/homeComponents/mainContent";
import footerContent from "@/components/footerContent";
export default {
  components: {
    Head,
    mainContent,
    footerContent,
  },
  data() {
    return {};
  },
  // metaInfo() {
  //   return {
  //     title: "首页",
  //     meta: [
  //       {
  //         name: "description",
  //         content: "这是一个演示 Vue-meta 的示例项目",
  //       },
  //       {
  //         name: "keywords",
  //         content: "vue-meta, 示例, 示例项目",
  //       },
  //     ],
  //   };
  // },
};
</script>
<style lang="less" scoped>
.mainContent {
  //background: #ccc;
  // text-align: center;
  margin-bottom: 5rem;
}

/deep/ .el-card_body,
.el-main {
  padding: 0px;
  overflow: hidden;
}
.contenter {
  display: flex; /* 容器为flex布局 */
  flex-direction: column; /* 方向为纵向，保持正常的从上到下文档流 */
}
</style>