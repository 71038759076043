<template>
  <div>
    <div class="mainContentStyle publishStyle">
      <el-form ref="form" :model="form" :rules="rules" label-width="180px">
        <!-- <div class="header-l">
          <i></i>
          <span>填写资料</span>
        </div> -->
        <div style="display:flex;flex-wrap:wrap;">
          <!-- <el-form-item label="选择分类" prop="chexing">
            <el-select
              v-model="form.chexing"
              placeholder="请选择分类"
              class="formItemStyle"
            >
              <div v-for="(item, index) in chexingList" :key="index">
                <el-option
                  :label="item.chexingTitle"
                  :value="item.chexingId"
                ></el-option>
              </div>
            </el-select>
          </el-form-item> -->
          <el-form-item label="选择品牌" prop="pinpai">
            <el-select
              v-model="form.pinpai"
              placeholder="请选择品牌"
              class="formItemStyle"
            >
              <div v-for="(item, index) in pinpaiList" :key="index">
                <el-option
                  :label="item.pinpaiName"
                  :value="item.pinpaiId"
                ></el-option>
              </div>
            </el-select>
          </el-form-item>
      

        <el-form-item label="联系人姓名" prop="lxrname">
          <el-input
            v-model="form.lxrname"
            class="formItemStyle"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="lxrphone">
          <el-input
            v-model="form.lxrphone"
            class="formItemStyle"
            placeholder="请输入联系方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="title">
          <el-input
            v-model="form.title"
            class="formItemStyle"
            placeholder="请输入设备名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="厂家" prop="changjia">
          <el-input
            v-model="form.changjia"
            class="formItemStyle"
            placeholder="请输入厂家"
          ></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="xinghao">
          <el-input
            v-model="form.xinghao"
            class="formItemStyle"
            placeholder="请输入型号"
          ></el-input>
        </el-form-item>


        <el-form-item label="设备新旧程度" prop="degree">
          <el-input
            v-model="form.degree"
            class="formItemStyle"
            placeholder="请输入设备新旧程度"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备所在地" required>
          <el-cascader
            v-model="form.address"
            :options="addressData"
            @change="handleChangeAddress"
            placeholder="请选择设备所在地"
            class="formItemStyle"
          ></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="价格" prop="jiage">
          <el-input
            v-model="form.jiage"
            class="formItemStyle"
            placeholder="请输入价格"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="设备介绍" prop="content">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="出厂时间">
          <el-col :span="11">
            <el-date-picker
              type="date"
              class="formItemStyle"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              v-model="form.leave_time"
            ></el-date-picker>
          </el-col>
        </el-form-item>
   

      </div>
      <!-- <el-form-item label="是否包含运费" required>
          <el-switch v-model="isFree" @change="isFreeChange"></el-switch>

        </el-form-item> -->
        <el-form-item label="请上传产品图片">
          <el-upload
            action=""
            :http-request="upload"
            list-type="picture-card"
            :class="{ disabled: uploadDisabled }"
            :auto-upload="true"
            :limit="6"
            ref="upload"
            :on-change="handleChange"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleImagesSuccess"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- 预览图片 -->
          <el-dialog :visible.sync="dialogVisibleimg" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <el-form-item style="margin-left:27%">
                   <el-button type="primary" @click="onSubmit">立即发布</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { regionData } from "element-china-area-data";
import city from "@/assets/ciityData/city.json";
export default {
  name: "equipmentEvaluationPublish",
  components: {},
  data() {
    return {
      form: {
        images:''
      },
      // newProps:{
      //   value: 'id',                 //匹配响应数据中的id
      //   label: 'name',               //匹配响应数据中的name
      //   children: 'children'         //匹配响应数据中的children
      // },
      dialogVisibleimg: false,
      dialogImageUrl: "",
      formdata: new FormData(),
      fileList: [],
      addimg: [],
      removeimg: [],
      ruleForm: {
        csAvatar: "",
      },
      pinpaiList: [],
      chexingList: [],
      addressData: city.data,
      selectedOptions: [],
      imagesArr: [],
      isFree: false,
      rules: {
        chexing: [{ required: true, message: "请选择分类", trigger: "blur" }],
        lxrphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        lxrname: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        pinpai: [{ required: true, message: "请选择品牌", trigger: "blur" }],
        title: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        changjia: [
          { required: true, message: "请输入厂家名称", trigger: "blur" },
        ],
        xinghao: [{ required: true, message: "请输入型号", trigger: "blur" }],
        is_free: [
          { required: true, message: "请选择是否包含运费", trigger: "blur" },
        ],
        degree: [
          { required: true, message: "请输入设备新旧程度", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请选择设备所在地", trigger: "blur" },
        ],
        jiage: [{ required: true, message: "请输入价格", trigger: "blur" }],
        content: [
          { required: true, message: "请输入设备介绍", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getData();
    // this.getAddress()
  },
  computed: {
    uploadDisabled: function () {
      console.log(this.ruleForm.csAvatar);
      return this.ruleForm.csAvatar != "";
    },
  },
  methods: {
    upload(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      this.$axios
        .post("/api/SystemInterface/uploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data.code);
          // //根据返回值进行判断是否上传成功
          if (response.data.code == "0") {
            //上传成功
            this.$message.success(response.data.info);

            this.dialogImageUrl = response.data.data;
            this.form.images= response.data.data;
           
          } else {
            //上传失败
            this.$message.error(response.data.info);
          }
        })
        .catch((response) => {
          console.log("异常处理");
        });
    },
    onSubmit() {
      const userinfo = JSON.parse(localStorage.getItem("userinfo"));
      // images
      
      this.form.userId = userinfo.userId;

      console.log(this.form)
      this.$refs["form"].validate((valid) => {
        if (valid) {
          axios.post("/api/user.Assess/addAssess", this.form).then((res) => {
            console.log("发布设备", res);
            if (res.data.code === "0") {
              this.$message.success(res.data.info);
              this.$bus.$emit("state", { state: false, });
            } else {
              this.$message.error(res.data.info);
            }
          });
        }
      });
    },
    // 添加活动展示照片
    handleChange(file, fileList) {
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 、png 格式!");
        fileList.splice(-1, 1); //移除错误文件
        return false;
      }
      if (!isLt5M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
        fileList.splice(-1, 1);
        return false;
      }
      this.addimg = fileList[0].raw;
      this.ruleForm.csAvatar = this.addimg;
    },
    // 删除活动展示照片
    handleRemove(file, fileList) {
      console.log(fileList);
      this.ruleForm.csAvatar = "";
      this.formdata = new FormData();
    },
    // 活动展示照片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleimg = true;
    },
    // 渲染编辑获取id
    apply() {
      this.fileList = [{ url: "" }]; //这里是踩坑的点，必须写
      this.fileList[0].url = res.data.csAvatar; //这里是调用接口获取到的值 res.data.csAvatar，赋值就可以回显了
      //this.fileList[0].url 做的是单张图片回显，多张图片回显可以去掉[0]
    },
    getData() {
      axios.post("/api/SystemInterface/getDictList").then((res) => {
        this.pinpaiList = res.data.data.pinpaiList;
        this.chexingList = res.data.data.chexingList;
      });
    },
    getAddress() {
      axios.post("/api/SystemInterface/getLocationList").then((res) => {
        this.addressData = res.data.data;
      });
    },
    handleChangeAddress(e) {
      console.log(e);
      this.form.sheng_id = e[0];
      this.form.shi_id = e[1];
    },
    handleImagesSuccess(res) {
      if (res.code === "0") {
        this.imagesArr.push(res.data);
        this.form.images = this.imagesArr.join(",");
      }
    },
    isFreeChange(e) {
      e ? (this.form.is_free = 1) : (this.form.is_free = 2);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-textarea{
  width: 330px;
}
/deep/.el-input--suffix .el-input__inner {
    width: 321px;
}
/deep/.el-button--primary {

    width: 234px;
    margin: 0 auto;
}
.header-l {
  display: flex;
  align-items: center;
  i {
    width: 5px;
    height: 16px;
    background: #ff5e5e;
    border-radius: 5px 5px 5px 5px;
  }
  span {
    margin-left: 6px;
    font-size: 20px;
    font-weight: 600;
    color: #010101;
  }
}
.publishStyle {
  //margin: 40px 30%;
  text-align: left;
  // z-index: 9999 !important;
}
.formItemStyle {
  width: 20rem;
}
.mainContentStyle {
  // margin: 0 15%;
  margin-top: 20px;
}
</style>