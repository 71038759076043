<template>
  <div>
    <div class="headTop" ><headBox></headBox></div>
    <!-- <div class="headTop"><el-header class="header" ><headBox></headBox></el-header></div> -->
<!--    轮播图-->
    <carousel ></carousel>
    <div class="mainContentStyle">
      <!-- 分类 -->
      <classification></classification>
      <!--    品牌-->
      <brand title="品牌" :listData="pinpaiList"></brand>
      <!--    设备-->
      <device title="设备" :listData="chexingList"></device>
      <!--    列表信息-->
      <!-- <infoList></infoList> -->
       <newsList></newsList>
     
    </div>

  </div>
</template>

<script>
import carousel from "@/components/carousel";
import brand from "@/views/homeComponents/brand";
import classification from "@/views/homeComponents/classification";
import device from "@/views/homeComponents/device";
import newsList from "@/views/homeComponents/newsList";
// import infoList from "@/views/homeComponents/infoList";
import headBox from "@/components/Header";
export default {
  name: "mainContent",
  components:{
    carousel,
    brand,
    classification,
    // infoList,
    headBox,
    device,
    newsList
  },
  data() {
    return {
      ageList:[],
      chexingList:[],
      jiageList:[],
      pinpaiList:[],
      reportlist:[],
      sysconfig:[]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      let that = this
      this.$axios.post('/api/SystemInterface/getDictList',).then((res)=>{
        this.ageList = res.data.data.ageList
        this.pinpaiList = res.data.data.pinpaiList
        this.chexingList = res.data.data.chexingList
        that.$pinpaiList = this.pinpaiList
        that.$chexingList = this.chexingList
        this.jiageList = res.data.data.jiageList
        this.reportlist = res.data.data.reportlist
        this.sysconfig = res.data.data.sysconfig
      })
    }
  },
}
</script>

<style lang="less">
.mainContentStyle{
  width: 1200px;
    margin: 0 auto;
  position: relative;
}
.headTop{
  // background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 27px 0px rgba(1, 1, 1, 0.1);
  // position: absolute;
  // left: 2px;
  // top: -2px;
  // width: 1918px;
  // height: 151px;
  // z-index: 2;
}
</style>