<template >
  <div style="display:flex;width: 1200px;margin:0 auto;" >
    <div class="header-l" style="display: flex;flex:2;">
      <img
        src="../assets/images/home/header-img.png"
        alt=""
        class="header-img" @click="homeC"
      />
      <h3 class="header-h3">{{title}}</h3>
    </div>
    <div class="grid-content" style="flex:8">
      <navLine></navLine>
    </div>
  </div>
  <!-- <el-row :gutter="20" justify="center" style="width: 90%;margin: 0 auto;">
    <el-col :span="3">
      <el-popover
          class="grid-content positioningFlex"
          placement="right"
          width="400"
          trigger="click">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" class="treeBox"></el-tree>
        <div slot="reference" class="positioning">
          <el-image class="imgBox" :src="require('../assets/icon/weizhi.png')"></el-image>{{location}}</div>
      </el-popover>
    </el-col>
    <el-col :span="6" class="flexContent"
    >
      <div class="bg-search">
        <div style="width:20rem">
          <el-input v-model="searchInput" placeholder="搜索您想要的车辆编号"></el-input>
        </div>
        <div class="searchIcon" @click="search"></div>
      </div>
    </el-col>



    <el-col :span="5" style="display:flex">
      <img src="../assets/images/home/header-img.png" alt="" class="header-img">
      <h3 class="header-h3">二手钢筋设备网</h3>
    </el-col>
    <el-col :span="15"
    >
      <div class="grid-content">
        <navLine></navLine>
      </div
      >
    </el-col>
  </el-row> -->
</template>
<script>
import navLine from "@/components/navLine";
import city from "@/assets/ciityData/city.json";
export default {
  name: "Header",
  components: {
    navLine,
  },
  data() {
    return {
      input: "",
      data: city.data,
      title:'',
      defaultProps: {
        children: "children",
        label: "label",
      },
      location: "全国",
    };
  },
  mounted() {
    this.$searchKey = "";
    this.location = JSON.parse(localStorage.getItem("location")).label;
  },
  created() {
    this.$axios.post("/api/SystemInterface/getDictList").then((res) => {
  
      this.title = res.data.data.sysconfig.name;

    });


  },
  computed: {
    searchInput: {
      set(val) {
        this.$searchKey = val;
        this.input = val;
        console.log(this.$searchKey);
      },
      get() {
        return this.input;
      },
    },
  },
  methods: {
    homeC(){
      this.$router.push({ name: "home"});
    },
    search() {
      this.$router.push({ name: "search", params: { keywords: this.input } });
    },
    handleNodeClick(e) {
      this.location = e.label;
      const local = {
        label: e.label,
        value: e.value,
      };
      localStorage.setItem("location", JSON.stringify(local));
      location.reload();
    },
  },
};
</script>
<style lang="less" scoped>
//让导航栏置顶
// .headTop {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 100;
// }
.header-h3{
  margin-left: 10px;
  width: 183px;
  
}
.header-img {
  width: 63px;
  height: 56px;
 cursor: pointer;
}
.header {
  // background: #1096ff;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  color: #fff;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.flexContent {
  // display: flex;
  height: 5.75rem;
  line-height: 5.75rem;
}

.bg-search {
  background: #ededed40;
  border-radius: 10px;
  height: 3.25rem;
  line-height: 3.25rem;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.searchIcon {
  background: url("~@/assets/icon/search.png") no-repeat;
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
  line-height: 3.25rem;
  cursor: pointer;
}

/deep/ .el-input__inner {
  -webkit-appearance: none;
  background-color: #f56c6c00;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe600;
  box-sizing: border-box;
  color: #dfdfdf;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-size: 20px;
}

.positioning {
  width: 100px;
  height: 40px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.positioningFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.imgBox {
  width: 30px;
  height: 40px;
}
.treeBox {
  height: 40rem;
  overflow: auto;
}
</style>
