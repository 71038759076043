<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!--    <div class="headTop"><el-header class="header" height="5.75rem"><headBox></headBox></el-header></div>-->
    <!--    <keep-alive>-->
    <router-view v-if="isRouterAlive" />
    <!--    </keep-alive>-->

    <!--    <el-footer class="footer" height="10rem"></el-footer>-->
  </div>
</template>
<script>
import headBox from "./components/Header";
export default {
  name: "App",
  components: {
    headBox,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  created() {
    this.$axios.post("/api/SystemInterface/getDictList").then((res) => {
      // let titleH = document.getElementById("titleH");
      // titleH.innerHTML = res.data.data.sysconfig.title;
      // let keywords=document.getElementById("keywords")
      // keywords.setAttribute('content', res.data.data.sysconfig.keywords);
      // let description=document.getElementById("description")
      // description.setAttribute('content', res.data.data.sysconfig.description);
      const body = document.body;
      let app = document.getElementById("app").innerHTML;
      let newDiv = document.createElement("div");
      newDiv.innerHTML = app;
      body.appendChild(newDiv);
      newDiv.style.display = "none";
    });

    // //在页面加载时读取sessionStorage里的状态信息
    // if (sessionStorage.getItem("store") ) {
    //   this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    // }
    // //在页面刷新时将vuex里的信息保存到sessionStorage里
    // if(JSON.stringify(this.$store.state) !== '{}'){
    //   window.addEventListener("beforeunload",()=>{
    //     sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    //   })
    // }
  },

  data() {
    return {
      isRouterAlive: true,
      hide: false,
    };
  },
  created(){
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      this.$router.replace("/Mbindex");
    } else {
      this.$router.replace("/home");
     
    }
  },
  mounted() {
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="less" >
html,
body {
  margin: 0;
  // height: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //padding-top: 5.75rem;
}

.header {
  background: #fff;
  height: 60px;
}
.footer {
  //position: absolute;
  bottom: 0;
  background: #484848;
  width: 100%;
  height: 14em !important;
}
/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 8px;
}
//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #c9c8c899;
  border-radius: 3px;
}
</style>
