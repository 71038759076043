<template>
  <!--  <div class="nav">-->
  <!--    <el-row  type="flex" class="row-bg" justify="space-around">-->
  <!--      <el-col :span="4" v-for="(item, index) in navList" :key="item"  @click="changeTitle(index)" :class="{ 'nav-list&#45;&#45;active': index === active }">-->
  <!--        <div class="grid-content clickStyle">{{ item }}</div></el-col-->
  <!--      >-->
  <!--    </el-row>-->
  <!--  </div>-->
  <div class="nav-box">
    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="/"
        ><router-link to="/home">首页</router-link></el-menu-item
      >
      <el-menu-item index="/BrandClassification"
        ><router-link to="/BrandClassification"
          >设备中心</router-link
        ></el-menu-item
      >
      <el-menu-item index="/publish"
        ><router-link to="/publish">我要发布</router-link></el-menu-item
      >
      <el-menu-item index="/informationList"
        ><router-link to="/informationList">行业资讯</router-link></el-menu-item
      >
      <el-menu-item index="/afterSales"><router-link to="/afterSales">售后服务</router-link></el-menu-item>
      <el-menu-item index="/personalCenter"
        ><router-link to="/personalCenter">个人中心</router-link></el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      navList: ["首页", "我的店铺", "我要发布", "实名认证", "个人中心"],
      active: 0,
      lineColor: "#333",
      activeIndex: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.nav-box{
  width: 800px;
  margin: 0 auto;
  margin-left: 18%;
}
.nav-list--active {
  font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
  font-weight: bold;
  color: #1096ff;
  transition: all 0.3s;
}

.clickStyle {
  font-size: 20px;
}

.clickStyle:hover {
  color: #1096ff;
  cursor: pointer;
}

.clickStyle:active {
  color: #1096ff;
}

.el-menu {
  display: flex;
  // height: 6.75rem;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background: #fff0;
}

.el-menu-item {
  font-size: 18px;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background: #fff0;
}

.el-menu--horizontal > .el-menu-item {
  color: #fff;
}
.el-menu--horizontal > .el-menu-item {
  color: #000;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: #fff0;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  //color: #0090FF;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid transparent;
}
.el-menu--horizontal > .el-menu-item a:hover {
  color: #0090ff;
}
.el-menu--horizontal > .el-menu-item {
  height: 48px;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
</style>
