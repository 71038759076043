import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store =  new Vuex.Store({
  // 全局变量
  state: {
    isLogin:'0',
    ser:null,
    token:localStorage.getItem('token') ? localStorage.getItem('token'):'',   // token
  },
  getters: { // 监听数据变化的
    getStorage(state){   // 获取本地存储的登录信息
      if(!state.token){
        state.token =JSON.parse(localStorage.getItem(key))
      }
      return state.token
    }
  },
  // 修改全局变量必须通过mutations中的方法
  mutations: {
    $_setToken(state, value) { // 设置存储token
      state.token = value;
      localStorage.setItem('token', value);
    },
    $_removeStorage(state, value){  // 删除token
      localStorage.removeItem('token');
      localStorage.removeItem('userinfo');
      localStorage.removeItem('sessionId');
    },
    $_sessionId(state, value) { // 设置存储sessionId
      state.sessionId = value;
      localStorage.setItem('sessionId', value);
    },
    $_userinfo(state, value) { // 设置存储sessionId
      state.userinfo = value;
      localStorage.setItem('userinfo', value);
    },
  },
  // 异步方法用actions
  // actions不能直接修改全局变量，需要调用commit方法来触发mutations中的方法
  actions: {
    login (context, payload) {
      context.commit('login',payload)
    },
    logout (context) {
      context.commit('logout')
    }
  },
  modules: {
  }
})
function f() {

}
export default store