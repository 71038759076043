import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import qs from 'qs';
import md5 from 'js-md5' //引入
import * as filters from './utils/formatTime'
import getSystemData from "@/utils/systemData";
import bus from "@/utils/vueBus";
// import request from "@/utils/request";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]) // 插入过滤器名和对应方法
})

import VueMeta from 'vue-meta';

Vue.use(VueMeta);

Vue.prototype.$md5 = md5;//添加

Vue.prototype.$bus = new Vue();


let sessionId = localStorage.getItem('sessionId')
console.log(sessionId)

// let userinfo=localStorage.getItem('userinfo');

// let userId=JSON.parse(userinfo).userId
// if(sessionId === '' || sessionId == null){
//     sessionId= ''
//   }else{
//     sessionId=localStorage.getItem('sessionId')
//   }
//   if(userId === '' ||userId == null){
//     userId = '2'
//   }else{
//     userId=JSON.parse(userinfo).userId
//   }
//   const now = new Date();
//   const year = now.getFullYear();
//   const month = String(now.getMonth() + 1).padStart(2, '0');
//   const day = String(now.getDate()).padStart(2, '0');
//   const time = year.toString()+month+day

// let token = md5(this.sessionId+this.userId+this.cityCode+'jiudianlianxian'+time)





// 2.2 设置请求拦截器
// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use(config => {

  //为请求头对象，添加token验证的Authorization字段
  // config.headers.Authorization = window.sessionStorage.getItem("token")
  let sessionId = localStorage.getItem('sessionId')
  // console.log(sessionId)
  let userId;
  let userinfo = localStorage.getItem('userinfo');
  if (userinfo === '' || userinfo == null) {

  } else {

    userId = JSON.parse(userinfo).userId
  }



  if (sessionId === '' || sessionId == null) {
    sessionId = ''
  } else {
    sessionId = localStorage.getItem('sessionId')
  }
  if (userId === '' || userId == null) {
    userId = '2'
  } else {
    userId = JSON.parse(userinfo).userId
  }
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const time = year.toString() + month + day
  let token = md5(sessionId + userId + '-1' + 'jiudianlianxian' + time)
  let obj = qs.parse(config.data)
  if (config.data) {
    if(typeof config.data.get==="function"){
      if (config.data.get("file")) {
        config.data = config.data
      }
    }else{
      if (obj.cityCode) {
        obj.token = token;
        obj.sessionId = sessionId
        obj.userId = userId
        config.data = obj
      } else {
        obj.token = token;
        obj.sessionId = sessionId
        obj.userId = userId
        obj.cityCode = '-1'
        config.data = obj
      }
    }
  }else{
    obj.token = token;
    obj.sessionId = sessionId
    obj.userId = userId
    obj.cityCode = '-1'
    config.data = obj
  }
  return config
})
Vue.config.productionTip = false
// 挂载一个自定义属性$http
Vue.prototype.$axios = axios
// Vue.prototype.$axios = request
// 全局配置axios请求根路径(axios.默认配置.请求根路径)
// axios.defaults.baseURL = 'https://esgjsb.jiudianlianxian.com/index.php'
// axios.defaults.baseURL = 'https://esgjsb.jiudianlianxian.com'
axios.defaults.baseURL = '/index.php'
axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$userinfo = localStorage.getItem('userinfo');
Vue.prototype.$sessionId = localStorage.getItem('sessionId');
// const token = localStorage.getItem('token');
// axios.defaults.headers.common['token'] = token;
// axios.defaults.headers.common['userId'] = userinfo.userId;
Vue.use(ElementUI);
Vue.prototype.$host = 'https://www.esgjsb.com';
Vue.prototype.$searchKey = '';//头部搜索关键字

// Vue.use(getSystemData)


// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面meta */
//   console.log(to.meta.content)
//   if(to.meta.content){
//     let head = document.getElementsByTagName('head');
//     let meta = document.createElement('meta');
//     document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
//     document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
//     meta.content = to.meta.content;
//     head[0].appendChild(meta)
//   }
//   // /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
//   next()
// });






new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
