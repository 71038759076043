<template>
  <div class="footerBox">
    <div>
      <!--      <div>{{footerData.keywords}}</div>-->
      <!-- <div class="name">{{ footerData.description }}</div> -->
      <div class="cl">客服电话: {{ footerData.phone }}</div>
      <div class="cl">微信客服: {{ footerData.wxnumber }}</div>
      <!-- <div class="cl">售后服务热线: {{ footerData.after_phone }}</div> -->
      <div class="cl" @click="txnewlink()" style="cursor: pointer;">备案号: {{ footerData.record }}</div>
      <div class="cl">
        <span>版权所有: {{ footerData.copyright }}</span>
      </div>
    </div>
    <div class="wxewm">
      <img :src="host+footerData.wximg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "footerContent",
  data() {
    return {
      footerData: "",
      host: this.$host,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    txnewlink(){
      window.open('https://beian.miit.gov.cn/#/Integrated/recordQuery'); 
    },
    getData() {
      this.$axios.post("/api/SystemInterface/getDictList").then((res) => {
        console.log("ffotrt", res);
        this.footerData = res.data.data.sysconfig;

      });
    },
  },
};
</script>

<style lang="less">
.footerBox {
  color: white;
  margin: 0 20%;
  text-align: left;
  padding: 20px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .cl {
    margin-top: 5px;
  }
  .wxewm{
    width: 135px;
    height: 135px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}
</style>